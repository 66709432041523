import React, { useState } from "react";
import { Input } from "@material-tailwind/react";
// import { ProgresBar } from "../progressBar/ProgresBar";
// import { TableStrippedRow } from "../TableStrippedRow/TableStrippedRow";
// import { CustomButton } from "../customButton/CustomButton";

export const TaskModal = ({ onClose, title, type, configureTask, createTask }) => {

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    const [tasks, setTasks] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const handleAddTask = () => {  
        const newTask = {
            name: name,
            description: description,
            due: selectedOption

        };
        setTasks([...tasks, newTask]);
        setName('');
        setDescription(''); 
    };
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = '00';
        const minutes = '00';
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };
    const options = [
        { value: formatDate(tomorrow), label: '1 Dia' },
        { value: formatDate(addDays(today, 2)), label: '2 Dias' },
        { value: formatDate(addDays(today, 3)), label: '3 Dias' },
        { value: formatDate(addDays(today, 4)), label: '4 Dias' },
        { value: formatDate(addDays(today, 5)), label: '5 Dias' },
        { value: formatDate(addDays(today, 6)), label: '6 Dias' },
        { value: formatDate(addDays(today, 7)), label: 'Una semana' },
    ];
    const optionsCollaborators = [
        { value: '1', label: 'Head Field Manager' },
        { value: '2', label: 'City Manager' },
        { value: '3', label: 'Lider de Tienda' },
    ];
    function addDays(date, days) {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    };
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto mx-auto w-[40%]">
      <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
      <div className="bg-white rounded-lg p-8 w-full z-50 overflow-y-auto">
        <div className="flex justify-end">
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                />
                </svg>
            </button>
        </div>
        <div className="flex flex-col justify-center items-center w-full">
            <div className="flex justify-center items-center">
            <h2 className="text-2xl font-semibold text-[#004876]">{title}</h2>
            </div>
            {type === "single" && (
                <>
                    <div className="flex w-[35%] flex-col items-end gap-6 pt-7 rounded-lg">
                        <Input className="rounded-xl" variant="outlined" placeholder="Nombre"/>
                    </div>
                    <div className="flex w-[35%] flex-col items-end gap-6 pt-7 rounded-lg">
                        <Input className="rounded-xl" variant="outlined" placeholder="Descripcion"/>
                    </div>
                    <div className="w-[35%] pt-7">
                        <select className="bg-white rounded-xl w-full border border-gray-200 text-gray-400 h-9">
                            <option value="" disabled selected hidden>Duracion</option>
                            {options.map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="w-[35%] pt-7">
                        <select className="bg-white rounded-xl w-full border border-gray-200 text-gray-400 h-9">
                            <option value="" disabled selected hidden>Selecciona una colaborador</option>
                            {optionsCollaborators.map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pt-7">
                        <button onClick={configureTask} className="bg-[#FFC600] text-black px-4 py-2 rounded-md transform transition duration-300 hover:scale-110">Siguiente</button>
                    </div>
                </>
            )}
            {type === "configure" && (
                <>
                    <div class="inline-flex items-center">
                        <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                            <input type="checkbox"
                            class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                            id="check" />
                            <span
                            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                stroke="currentColor" stroke-width="1">
                                <path fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"></path>
                            </svg>
                            </span>
                        </label>
                        <label class="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="check">
                            Texto
                        </label>
                    </div>
                    <div class="inline-flex items-center">
                        <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                            <input type="checkbox"
                            class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                            id="check" />
                            <span
                            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                stroke="currentColor" stroke-width="1">
                                <path fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"></path>
                            </svg>
                            </span>
                        </label>
                        <label class="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="check">
                            Imagen
                        </label>
                    </div>
                    <div class="inline-flex items-center">
                        <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                            <input type="checkbox"
                            class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                            id="check" />
                            <span
                            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                stroke="currentColor" stroke-width="1">
                                <path fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"></path>
                            </svg>
                            </span>
                        </label>
                        <label class="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="check">
                            Video
                        </label>
                    </div>   
                    <div className="pt-7">
                        <button onClick={createTask} className="bg-[#FFC600] text-black px-4 py-2 rounded-md transform transition duration-300 hover:scale-110">Crear</button>
                    </div>
                </>
            )}
            {type === "list" && (
                <>
                    <div className="flex flex-row justify-center items-center w-full">
                        <div className="flex flex-col w-1/2 justify-center items-center">
                            <div className="flex w-[70%] flex-col items-end gap-6 pt-7 rounded-lg">
                                <Input className="rounded-xl" variant="outlined" placeholder="Nombre" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="flex w-[70%] flex-col items-end gap-6 pt-7 rounded-lg">
                                <Input className="rounded-xl" variant="outlined" placeholder="Descripcion" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </div>
                            <div className="w-[70%] pt-7">
                                <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)} className="bg-white rounded-xl w-full border border-gray-200 text-gray-400 h-9">
                                    <option value="" disabled selected hidden>Duracion</option>
                                    {options.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </div>
                            
                            <div className="pt-7">
                                <button onClick={handleAddTask} className="bg-[#004876] text-white px-4 py-2 rounded-md transform transition duration-300 hover:scale-110">Agregar</button>
                            </div>
                            <div className="flex flex-row justify-center">
                                <button class="pr-3 material-symbols-outlined text-[#004876] text-4xl transform transition duration-300 hover:scale-110 pt-4">
                                    file_save
                                </button>
                                <button class="material-symbols-outlined text-[#004876] text-4xl transform transition duration-300 hover:scale-110 pt-4">
                                    upload_file
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col w-1/2 items-center h-[350px] bg-[#f0f0f0] overflow-auto">
                            <table className="w-full">
                                <thead className="sticky top-0">
                                    <tr className="bg-[#f0f0f0]">
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Fecha de Vencimiento</th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {tasks.map((task, index) => (
                                        <tr key={index}>
                                            <td>{task.name}</td>
                                            <td>{task.description}</td>
                                            <td>{task.due}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className="w-full flex flex-col justify-center items-center">
                        <div className="w-[35%] pt-7">
                            <select className="bg-white rounded-xl w-full border border-gray-200 text-gray-400 h-9">
                                <option value="" disabled selected hidden>Selecciona una colaborador</option>
                                {optionsCollaborators.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="pt-7">
                            <button onClick={configureTask} className="bg-[#FFC600] text-black px-4 py-2 rounded-md transform transition duration-300 hover:scale-110">Crear Tareas</button>
                        </div>
                    </div>
                </>
            )}
        </div>
      </div>
    </div>
  );
};
