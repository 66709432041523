import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

export const RequireAuth = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 50); // 500 milisegundos de retraso (medio segundo)

    return () => clearTimeout(delay); // Limpiar el temporizador al desmontar el componente
  }, []);

  if (isLoading) {
    return null; // O cualquier otro indicador de carga que desees mostrar
  }

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return children;
};
