import React, { useState } from "react";
import { Card, Typography } from "@material-tailwind/react";


export const TableStrippedRow = ({ TABLE_ROWS, TABLE_HEAD, onView, filterData, startDate, handleStartDateChange, endDate, handleEndDateChange, resetData, formattedStartDate, formattedEndDate, canFilter }) => {
    const [searchTerm, setSearchTerm] = useState(""); 
    const [showModal, setShowModal] = useState(false);


    const searchRows = (rows, term) => {
        return rows.filter(row => {
            return Object.values(row).some(value =>
                value.toString().toLowerCase().includes(term.toLowerCase())
            );
        });
    };

    const handleFilterButton = () => {
        setShowModal(!showModal); 
    }

    return (
        <div>
            <div className="flex justify-end">
                {showModal && (
                    <div className="bg-[#fdfdfd] p-4 border rounded-md border-gray-300 absolute z-10 animate-jump-in">
                        <div className="flex flex-row">
                            <p className="font-bold mb-2">Filtrar</p>
                            <div className="flex justify-end w-full">
                                <button onClick={handleFilterButton} className="material-symbols-outlined">
                                    close
                                </button>
                            </div>
                        </div>
                        <div className="flex justify-between items-center mb-4">
                            <input type="date" value={startDate} onChange={handleStartDateChange} className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none" />
                            <span className="mx-2">-</span>
                            <input type="date" value={endDate} onChange={handleEndDateChange} className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none" />
                        </div>
                        <button onClick={filterData} className="bg-[#FFC600] font-bold text-black px-4 py-2 rounded-md">Aplicar</button>
                        <button onClick={resetData} className="bg-[#004876] ml-3 font-bold text-white px-4 py-2 rounded-md">Resetear</button>
                    </div>
                )}
            </div>
            <div className="pb-3 flex items-center justify-end">
                
                {canFilter && (<div className="px-9">
                    <p className="font-bold">Periodo:</p>
                    <p>{formattedStartDate} - {formattedEndDate}</p>
                </div>)}
                {canFilter && (<button onClick={handleFilterButton}>
                    <span className="material-symbols-outlined">
                        filter_alt
                    </span>
                </button>)}
                
                <input
                    type="text"
                    placeholder="Buscar..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="px-2 py-1 border border-gray-300 rounded-md focus:outline-none"
                />
            </div>
            <Card className="h-[550px] w-full overflow-scroll">
                <table className="w-full min-w-max table-auto text-left">
                    <thead className="sticky top-0">
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th key={head} className="border-b font-bold border-[#F0F0F0] bg-[#D5D4D4] p-4 ">
                                    <Typography
                                        variant="small"
                                        color="black"
                                        className="font-bold leading-none "
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {searchRows(TABLE_ROWS, searchTerm).map((rowData, index) => (
                            <tr key={index} className={index % 2 === 0 ? "bg-[#F0F0F0]" : ""}>
                                {Object.values(rowData).map((value, cellIndex) => (
                                    <td key={cellIndex} className="p-4">
                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                            {value}
                                        </Typography>
                                    </td>
                                ))}
                                <td className="p-4">
                                    <button
                                        className="material-symbols-outlined text-black"
                                        onClick={() => onView(rowData)}
                                    >
                                        Visibility
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card>
        </div>
    );
};
