import React from "react";
import { useAuth } from "../../context/AuthProvider";


export const TopBar = () => {

  const { isLoggedIn } = useAuth();
  const firstName = localStorage.getItem('firstName');

  return (
    <div className="pl-16 flex justify-between max-[430px]:pl-0">
      <div className="p-5 text-2xl font-bold">
        <h1 className="flex justify-center items-center text-3xl mb-4 font-bold my-2 mx-[20%]">
          <span className="text-[#FFC600]">DAY </span>
          <span className="text-[#004870]">TASK</span>
        </h1>
      </div>
      <div className="flex justify-center items-center">
        <p className="pr-5">{isLoggedIn ? 'Hola, '+firstName : ''}</p>
      </div>
    </div>
  );
};
