import React, { useState } from 'react'
import { TopBar } from '../../components/topBar/TopBar';
import { Navbar } from '../../components/navbar/Navbar';
import { Card } from '../../components/card/Card';
import { TableStrippedRow } from '../../components/TableStrippedRow/TableStrippedRow';
import { ModalCustom } from '../../components/modal/ModalCustom';

export const Stores = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStore, setSelectedStore] = useState(null);

    const handleOnView = (store) => {
        setSelectedStore(store);
        setIsModalOpen(true);
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedStore(null);
    }

    const TABLE_HEAD = ["Nombre Sucursal", "Ubicacion", "Healt", "Visualizar"];
    const TABLE_ROWS = [
        {
          name: "Moliere",
          location: "Av Moliere",
          healt: "Verde",
        },
        {
          name: "San Joaquin",
          location: "Av San Joaquin",
          healt: "Amarilla",
        },
        {
            name: "Reloj Pachuca",
            location: "Pachuca Centro",
            healt: "Verde",
          },
          {
            name: "Rabbit",
            location: "Zona plateada Pachuca",
            healt: "Amarilla",
          },
          {
            name: "Estadio Monterrey",
            location: "Monterrey, Monterrey",
            healt: "Verde",
          },
          {
            name: "Acapulco",
            location: "Acapulco, Guerrero",
            healt: "Amarilla",
          },
      ];
    
    return (
        <div>
            <Navbar />
            <TopBar />
            <div className="flex flex-col pl-[4rem] flex max-[430px]:pl-0 max-[430px]:flex-col">
                <div className='flex pl-[4rem] max-[430px]:pl-0 max-[430px]:justify-center'>
                    <Card hiddeButton={true} title={'Total sucursales'} icon={'Store'} content={'85'}/>
                </div>
                <div className='pt-8 px-8'>
                    <TableStrippedRow TABLE_HEAD={TABLE_HEAD} TABLE_ROWS={TABLE_ROWS} onView={handleOnView}/>
                </div>
            </div>
            {isModalOpen && <ModalCustom store={selectedStore} onClose={handleModalClose} title={"Visualizar Sucursal"} subtitle={"Tareas activas en sucursal"} />}        
        </div>
    );
    }
