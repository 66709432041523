import { getPath } from '../helpers/getHost';
import { HttpRequest } from './HttpRequest';

export class TaskApi {
    constructor() {
        this.http = new HttpRequest()
        this.host = getPath()
        this.token = localStorage.getItem("token");
        this.config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.token}`
            }
        }
    }

    getTasks(){
        return this.http.get(`${this.host}/stores/task/`, this.config);
    }

}
