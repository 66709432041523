import React, { useEffect, useState } from 'react'
import { TopBar } from '../../components/topBar/TopBar';
import { Navbar } from '../../components/navbar/Navbar';
import { Card } from '../../components/card/Card';
import { TableStrippedRow } from '../../components/TableStrippedRow/TableStrippedRow';
import { FloatingAddButton } from '../../components/FloatingAddButton/FloatingAddButton';
import { ModalCustom } from '../../components/modal/ModalCustom';
import { TaskApi } from '../../api/stores';
import { formatTasksTable, filterTaskTableData } from '../../helpers/formatTable';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from '@mui/material/CircularProgress';
import { startOfWeek, endOfWeek, format } from 'date-fns';
import { es } from 'date-fns/locale';



export const Tasks = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [TABLE_HEAD, setTABLE_HEAD] = useState([]);
    const [TABLE_ROWS, setTABLE_ROWS] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [activeTasks, setActiveTasks] = useState([]);
    const [incompletedTasks, setIncompletedTasks] = useState([]);
    const [apiData, setApiData] = useState({});
    const [startDate, setStartDate] = useState(""); 
    const [endDate, setEndDate] = useState(""); 
    //    const completedTasks = tasksData.tasks.filter(task => task.completed === true);
    const [isLoading, setIsLoading] = useState(true);
    
    const today = new Date();
    const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1, locale: es });
    const endOfCurrentWeek = endOfWeek(today, { weekStartsOn: 1, locale: es });
    const [formattedStartDate, setFormattedStartDate] = useState(format(startOfCurrentWeek, "'Lunes' d 'de' MMMM", { locale: es }));
    const [formattedEndDate, setFormattedEndDate] = useState(format(endOfCurrentWeek, "'Domingo' d 'de' MMMM", { locale: es }));
    
    useEffect(() => {
      const tasksApi = new TaskApi();
      const today = new Date();
      const lastMonday = new Date(today);
      lastMonday.setDate(lastMonday.getDate() - (today.getDay() + 6) % 7 - 7); // Comienzo de la semana pasada (lunes)
      const lastSunday = new Date(lastMonday);
      lastSunday.setDate(lastSunday.getDate() + 6); 
      const initTasks = async () => {
          try {
              const response = await tasksApi.getTasks();
              if (response.status === 200) {
                  setActiveTasks(response.data.tasks.filter(task => task.is_active === true && task.completed === false));
                  setCompletedTasks(response.data.tasks.filter(task => task.completed === true));
                  setIncompletedTasks(response.data.tasks.filter(task => {
                    const dueDate = new Date(task.due_date);
                    return !task.completed && dueDate >= lastMonday && dueDate <= lastSunday; // Filtrar tareas cuya fecha de vencimiento está dentro de la semana pasada y no estén completadas
                  
                }));
                  const tableData = formatTasksTable(response.data);
                  setTABLE_HEAD(tableData.headers);
                  setTABLE_ROWS(tableData.formatData);
                  setApiData(response.data);
                  setIsLoading(false);
              }
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };

      initTasks();
  }, []);

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    }

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    }

    const handleOnView = (task) => {
        setSelectedTask(task);
        setIsModalOpen(true);
        console.log(selectedTask);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedTask(null);
    };

    const handleFilterData = () => {
        if(startDate === "" || endDate === ""){
            toast.success("No puedes dejar las fechas vacias", {
                type: 'error',
              });
        }else{
            const filteredData = filterTaskTableData(apiData, startDate, endDate);
            setTABLE_HEAD(filteredData.headers);
            setTABLE_ROWS(filteredData.formatData);
            setFormattedStartDate(startDate.toString());
            setFormattedEndDate( endDate.toString());
            setStartDate("");
            setEndDate("");
        }
    }

    const handleResetData = () =>{
        const tableData = formatTasksTable(apiData);
        setTABLE_HEAD(tableData.headers);
        setTABLE_ROWS(tableData.formatData);
        setFormattedStartDate(format(startOfCurrentWeek, "'Lunes' d 'de' MMMM", { locale: es }));
        setFormattedEndDate(format(endOfCurrentWeek, "'Domingo' d 'de' MMMM", { locale: es }));
    };

    
    return (
        <div>
            {isLoading && (
                <div className="absolute inset-0 flex justify-center items-center bg-opacity-50 bg-gray-800 z-50">
                    <CircularProgress style={{ color: '#FFC600' }} />
                </div>
            )}
            <Navbar />
            <TopBar />
            {/* <div className={isLoading ? "filter blur-lg" : ""}> */}
            <div className={isLoading ? "filter blur-lg flex flex-col pl-[4rem] flex max-[430px]:pl-0 max-[430px]:flex-col" :"flex flex-col pl-[4rem] flex max-[430px]:pl-0 max-[430px]:flex-col"}>
                <div className='flex flex-row justify-center max-[430px]:flex-col'>
                    <div className='flex px-9 max-[430px]:pt-4 max-[430px]:justify-center'>
                        <Card hiddeButton={true} title={'Tareas Completadas'} icon={'Task'} content={completedTasks.length}/>
                    </div>
                    <div className='flex px-9 max-[430px]:pt-4 max-[430px]:justify-center'>
                        <Card hiddeButton={true} title={'Tareas pendientes'} icon={'Task'} content={activeTasks.length}/>
                    </div>
                    <div className='flex px-9 max-[430px]:pt-4 max-[430px]:justify-center'>
                        <Card hiddeButton={true} title={'Tareas sin completar'} icon={'Task'} content={incompletedTasks.length}/>
                    </div>
                </div>
                <div className='pt-8 px-8'>
                    <TableStrippedRow 
                    TABLE_HEAD={TABLE_HEAD} 
                    TABLE_ROWS={TABLE_ROWS} 
                    onView={handleOnView} 
                    filterData={handleFilterData} 
                    startDate={startDate} 
                    endDate={endDate} 
                    handleStartDateChange={handleStartDateChange} 
                    handleEndDateChange={handleEndDateChange} 
                    resetData={handleResetData}
                    formattedStartDate={formattedStartDate}
                    formattedEndDate = {formattedEndDate}
                    canFilter={true}
                    />
                </div>
            </div>
            {isModalOpen && <ModalCustom onClose={handleModalClose} task={selectedTask} title={"Visualizar tarea"} />}
            <FloatingAddButton />  
            <ToastContainer />      
        </div>
    );
    }
