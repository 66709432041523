import React from "react";


export const Waves = ({ sidebarOpen }) => {
  
  return (
    <div className='box'>
        <div className='wave -one'> </div>
        <div className='wave -two'></div>
    </div>
  );
};
