import React from 'react'
//import { useState } from "react";
import { Waves } from '../../components/waves/waves'
import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const Login = () => {
    const { login } = useAuth();
    const { logout } = useAuth();
    const navigate = useNavigate();
    logout();
    const handleLogin = async (credential) => {
        // Llama a la función de inicio de sesión proporcionada por el contexto de autenticación
        
        const responseLogin = await login(credential);
        if(responseLogin === 'ok'){
            navigate('/dashboard');
        }else{
            if(responseLogin === 'error'){
                toast.success("Este correo no es valido.", {
                    type: 'error',
                  });
            }
        }
        
        
      };

    return (
        <div className="flex justify-center items-center h-screen ">
            <div className="bg-white shadow-xl rounded px-8 pt-6 pb-8 mb-4 w-[35%] max-[390px]:w-[75%] max-[414px]:w-[75%] max-[430px]:w-[75%]">
                <h1 className="flex justify-center items-center text-2xl mb-4 font-bold text-5xl my-2 mx-[20%]">
                    <span className="text-[#FFC600]">LOG</span>
                    <span className="text-[#004870]">IN</span>
                </h1>
                <div className="flex justify-center items-center mb-4">
                - WITH -
                </div>
                <div className='flex justify-center items-center'>
                <GoogleLogin
                onSuccess={credentialResponse => {
                    handleLogin(credentialResponse);
                }}
                onError={() => {
                    toast.success("Hubo un error al iniciar sesion.", {
                        type: 'error',
                      });
                }}
                /> 
                </div>
            </div>
        <Waves />
        <ToastContainer />
        </div>
    );
}
