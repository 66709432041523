import React from 'react';

export const TableData = ({TABLE_HEAD, TABLE_ROWS}) => {
console.log(TABLE_ROWS)
    return (
        <div className='shadow-md w-[85%]'>
            <p className="p-4 text-black font-bold">Tareas de la semana</p>
            <div className='flex flex-col justify-center items-center w-[100%] rounded-xl bg-white bg-clip-border text-gray-700 '>
                <div className='flex justify-end w-full'>
                    <button className="pr-4 material-symbols-outlined">
                        filter_alt
                    </button>
                </div>
                <div className='w-full rounded-xl bg-white bg-clip-border text-gray-700 '>
                <div className="relative overflow-x-auto h-[300px]">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead className="sticky top-0 text-xs text-gray-700 uppercase bg-gray-50 ">
                            <tr key={0}>
                                {TABLE_HEAD.map((head) => (
                                <th scope="col" className="px-6 py-3">
                                {head}
                            </th> 
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {TABLE_ROWS.map((rowData, index) => (
                                <tr key={index} className="bg-white border-b">
                                    {Object.values(rowData).map((value, cellIndex) => (
                                       <th key={cellIndex} scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                       {value}
                                        </th> 
                                    ))}
                                    <td className="p-4">
                                        <button
                                            className="material-symbols-outlined text-black"
                                        >
                                            Visibility
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
              </div>

            </div>
        </div>
    );
};

