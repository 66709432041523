import React, { useEffect } from 'react';

export const DotMap = ({jsonData}) => {
    let map;
    let circles = []; // Array para almacenar los círculos
    let infoWindows = [];
    window.handleWindowButton = (sucursal) => {
      alert('Clickeando ' + sucursal);
  };

    useEffect(() => {

      


      const mapOptions = {
        center: { lat: 23.6345, lng: -102.5528 }, // Centro en México
        zoom: 5,
        disableDefaultUI: true, // Deshabilita los controles por defecto
        styles: [
          { featureType: 'administrative', elementType: 'labels', stylers: [{ visibility: 'off' }] },
          { featureType: 'road', elementType: 'labels', stylers: [{ visibility: 'off' }] },
          { featureType: 'road.highway', elementType: 'labels', stylers: [{ visibility: 'off' }] },
          { featureType: 'poi', stylers: [{ visibility: 'off' }] },
          { featureType: 'road.highway', elementType: 'geometry', stylers: [{ visibility: 'off' }] },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#fffffff' }]
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [{ visibility: 'off' }] // Oculta la vegetación
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ visibility: 'off' }] // Oculta los límites de los parques
          },
          { featureType: 'transit', stylers: [{ visibility: 'off' }] },
          { featureType: 'landscape', stylers: [{ visibility: 'off' }] }, // Oculta el relieve natural, incluidos los cerros y montañas

        ]
      };
        
      // eslint-disable-next-line react-hooks/exhaustive-deps
      map = new window.google.maps.Map(document.getElementById('map'), mapOptions);

      jsonData.data.forEach(coord => {
        const circle = new window.google.maps.Circle({
            strokeColor: coord.color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: coord.color,
            fillOpacity: 0.35,
            map: map,
            center: { lat: coord.latitud, lng: coord.longitud }, // Coordenadas del círculo
            radius: 15000 // Radio del círculo en metros
        });

        circles.push(circle); // Agregar el círculo al array

        

        const infoWindowContent = `
          <div>
            <h3>${coord.name}</h3>
            <button onclick="(() => handleWindowButton('${coord.name}'))()">Descargar informe</button>
          </div>
        `;

        
        const infoWindow = new window.google.maps.InfoWindow({
          content: infoWindowContent,
        });
        

        infoWindows.push(infoWindow); // Agregar el infoWindow al array

        // Evento hover para mostrar el infoWindow
        window.google.maps.event.addListener(circle, 'mouseover', function () {
            infoWindow.setPosition(circle.getCenter()); // Establecer la posición del infoWindow en el centro del círculo
            infoWindow.open(map);
        });

        // Evento para cerrar el infoWindow al quitar el mouse
        window.google.maps.event.addListener(circle, 'mouseout', function () {
            infoWindow.close();
        });

        window.google.maps.event.addListener(map, 'zoom_changed', function () {
          const zoomLevel = map.getZoom();
          circles.forEach(circle => {
              const originalRadius = 15000; // Radio original del círculo en metros
              let adjustedRadius = originalRadius / Math.pow(2, (zoomLevel - 8)); // Ajuste del radio según el nivel de zoom
              adjustedRadius = Math.min(adjustedRadius, 15000); // Limitar el radio máximo a 15000 metros
              circle.setRadius(adjustedRadius);
          });
      });
    });


    }, []);
  
    return (
      <div className='w-[85%] rounded-xl bg-white bg-clip-border text-gray-700 shadow-xl'>
        <h1 className='pl-6 pt-6 text-black font-bold'>Mapa de Rendimiento</h1>
        <h2 className='pt-2 pl-6 pb-3 text-2xl'>{jsonData.data.length} sucursales</h2>
        <div className='pl-2 rounded-xl pt-2' id="map" style={{ height: '400px', width: '100%' }}></div>
      </div>
      
    );
};

