import axios from 'axios';
import { wrapper as axiosCookieJarSupport } from 'axios-cookiejar-support';
import tough from 'tough-cookie';

// Configurar axios-cookiejar-support
axiosCookieJarSupport(axios);

// Crear un nuevo jar de cookies
const cookieJar = new tough.CookieJar();

// Configurar Axios para usar el jar de cookies
axios.defaults.jar = cookieJar;

export default axios;