import React, { useState } from 'react'
import { TopBar } from '../../components/topBar/TopBar';
import { Navbar } from '../../components/navbar/Navbar';
import { Card } from '../../components/card/Card';
import { TableStrippedRow } from '../../components/TableStrippedRow/TableStrippedRow';
import { ModalCustom } from '../../components/modal/ModalCustom';

export const Collaborators = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleOnView = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedUser(null);
    }

    const TABLE_HEAD = ["Id" ,"Nombre", "Cargo", "Tipo empleado", "Visualizar"];
    const TABLE_ROWS = [
        {
          id: 3080,
          name: "John Michael",
          job: "Manager",
          date: "23/04/18",
        },
        {
          id: 3081,
          name: "Alexa Liras",
          job: "Developer",
          date: "23/04/18",
        },
        {
          id: 3082,
          name: "Laurent Perrier",
          job: "Executive",
          date: "19/09/17",
        },
        {
          id: 3083,
          name: "Michael Levi",
          job: "Developer",
          date: "24/12/08",
        },
        {
          id: 3084,
          name: "Richard Gran",
          job: "Manager",
          date: "04/10/21",
        },
        {
          id: 3085,
          name: "Alexa Liras",
          job: "Developer",
          date: "23/04/18",
        },
        
      ];
    
    return (
        <div>
            <Navbar />
            <TopBar />
            <div className="flex flex-col pl-[4rem] flex max-[430px]:pl-0 max-[430px]:flex-col">
                <div className='flex pl-[4rem] max-[430px]:pl-0 max-[430px]:justify-center'>
                    <Card hiddeButton={true} title={'Total empeados'} icon={'Group'} content={'85'}/>
                </div>
                <div className='pt-8 px-8'>
                    <TableStrippedRow TABLE_HEAD={TABLE_HEAD} TABLE_ROWS={TABLE_ROWS} onView={handleOnView}/>
                </div>
            </div>
            {isModalOpen && <ModalCustom user={selectedUser} onClose={handleModalClose} title={"Visualizar Colaborador"} subtitle={"Tareas asignadas"} />}        
        </div>
    );
    }
