export const ProgresBar = ({ title, progress }) => {

  const style = "w-["+progress+"%] bg-[#004870] h-4 rounded-full";
  return (
    <div className="bg-white w-[85%] h-48 rounded-md shadow-md">
      <h1 className="pl-6 pt-6 text-black font-bold">{title}</h1>
      <div>
        <h2 className="pt-4 pl-6 pb-3 text-2xl">Completada: {progress}%</h2>
      </div>
      <div className="flex flex-col items-center">
        <div className="w-[80%] bg-[#F0F0F0] rounded-full h-4">
          <div className={style}></div>
        </div>
      </div>
    </div>
  );
};
