import React, { useState } from "react";
import { TaskModal } from "../modal/TaskModal";

export const FloatingAddButton = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [title, setTitle] = useState("");
  const [isModalOpenConfig, setIsModalOpenConfig] = useState(false);
  const [isModalOpenList, setIsModalOpenList] = useState(false);

  const configureTask = () => {
    setIsModalOpenConfig(true);
    setTypeModal("configure");
    setTitle("Configurar Tarea");
  };

  const toggleMessage = () => {
    setShowMessage(!showMessage);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalCloseConfig = () => {
    setIsModalOpenConfig(false);
    setTypeModal("single");
    setTitle("Crear Tarea");
  };

  const handleModalCloseList = () => {
    setIsModalOpenList(false);
  }

  const handleSingleTask = () => {
    setIsModalOpen(true);
    setTypeModal("single");
    setTitle("Crear Tarea");
  };

  const handleListTask = () => {
    setIsModalOpenList(true);
    setTypeModal("list");
    setTitle("Lista de Tareas")
  };

  return (
    <div className="w-[15%] fixed bottom-8 right-8 flex justify-end">
      <button
        className="bg-[#004876] text-white rounded-full w-16 h-16 flex items-center justify-center text-3xl shadow-lg focus:outline-none"
        onClick={toggleMessage}
      >
        +
      </button>
      {showMessage && (
        <div className="bg-[#F0F0F0] p-4 rounded-lg shadow-lg absolute bottom-24 right-8 animate-jump-in">
          <button
            onClick={handleSingleTask}
            className="hover:text-[#004876] text-black py-2 px-4 rounded-lg transform transition duration-300 hover:scale-110"
          >
            Crear Tarea
          </button>
          <button
            onClick={handleListTask}
            className="hover:text-[#004876] text-black py-2 px-4 rounded-lg transform transition duration-300 hover:scale-110"
          >
            Crear Lista de Tareas
          </button>
        </div>
      )}
      {isModalOpen && (
        <TaskModal onClose={handleModalClose} title={title} type={typeModal} configureTask={configureTask} />
      )}
      {isModalOpenConfig && (
        <TaskModal onClose={handleModalCloseConfig} title={title} type={typeModal} />
      )}
      {isModalOpenList && (
        <TaskModal onClose={handleModalCloseList} title={title} type={typeModal} />
      )}
    </div>
  );
};
