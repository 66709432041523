import React from "react";


export const CustomButton = ({invisible, text, onClick, colorText }) => {

    let buttonClasses;
    if(invisible){
       buttonClasses = `bg-[] text-${colorText} font-bold py-2 px-4 rounded transition transform hover:scale-105`;
    }else{
       buttonClasses = `bg-[#FFC600] text-${colorText} font-bold py-2 px-4 rounded transition transform hover:scale-105`;
    }

  return (
    <button className={buttonClasses} onClick={onClick}>
      {text}
    </button>
  );
};
