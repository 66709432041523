export const formatTasksTable = (data) =>{
    //console.log(data);
    const headers = ["ID", "Tarea" ,"Descripcion", "Tipo de Usuario", "Visualizar"];
    const formatData = data.tasks.map(task => ({
        id: task.id || "",
        task: task.description || "",
        description: task.details || "",
        users: task.assigned_to || "",
    }));
    const TaskTable = {headers, formatData}
    return TaskTable;
}; 

export const filterTaskTableData = (data, startDate, endDate) => {
    const filteredTasks = data.tasks.filter(task => {
        const taskDueDate = new Date(task.due_date);
        return taskDueDate >= new Date(startDate) && taskDueDate <= new Date(endDate);
    });

    const headers = ["ID", "Tarea" ,"Descripcion", "Tipo de Usuario", "Visualizar"];
    const formatData = filteredTasks.map(task => ({
        id: task.id || "",
        task: task.description || "",
        description: task.details || "",
        users: task.assigned_to || "",
    }));
    
    return { headers, formatData };
};