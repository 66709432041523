// rutas.tsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Dashboard } from "../pages/dashboard/dashboard";
import { Login } from "../pages/login/login";
import {RequireAuth} from "./RequireAuth";
import { Collaborators } from "../pages/colaborators/Collaborators";
import { Stores } from "../pages/stores/Stores";
import { Tasks } from "../pages/taks/Tasks";

export const Rutas = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
                <Route path="/employes" element={<RequireAuth><Collaborators /></RequireAuth>} />
                <Route path="/stores" element={<RequireAuth><Stores /></RequireAuth>} />
                <Route path="/tasks" element={<RequireAuth><Tasks /></RequireAuth>} />
            </Routes>
        </Router>
    );
}
