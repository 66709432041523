import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

export const ColumnChart = ({categories, typeData, values}) => {
  // const firstName = categories.map(categoria => categoria.split(' ')[0]);
  useEffect(() => {

    const chartConfig = {
      series: [
        {
          name: typeData,
          data: values,
        },
      ],
      chart: {
        type: "bar",
        height: 240,
        toolbar: {
          show: false,
        },
        parentHeightOffset: 0,
      },
      title: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [{
              from: 0,
              to: 30,
              color: '#C60000'
            }, {
              from: 31,
              to: 70,
              color: '#FFC600'
            }, {
              from: 71,
              to: 100,
              color: '#52D25C'
            }]
          },
          columnWidth: "15%",
          borderRadius: 5,
        },
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
        categories: categories,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#dddddd",
        strokeDashArray: 0,
        xaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: 5,
          right: 20,
        },
      },
      fill: {
        opacity: 0.8,
      },
      tooltip: {
        theme: "dark",
      },
    };
    
    const chart = new ApexCharts(document.querySelector("#bar-chart"), chartConfig);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [categories, typeData, values]);

  const options = [
    { value: 'sucursal', label: 'Sucursal' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]

  return (
    <div className='w-[85%] rounded-xl bg-white bg-clip-border text-gray-700 shadow-md'>
        <h1 className='pl-6 pt-6 text-black font-bold'>Head field managers</h1>
        <h2 className='pt-2 pl-6 text-2xl'>{categories.length} Head field managers</h2>
        <div className='flex justify-end pr-4'>
          <select>
            {options.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        <div className="relative flex flex-col">
        <div className="relative flex flex-col gap-4 overflow-hidden rounded-none bg-transparent bg-clip-border text-gray-700 shadow-none md:flex-row md:items-center">
            
        </div>
        <div className="pt-6 px-2 pb-0">
            <div id="bar-chart"></div>
        </div>
        </div>
    </div>
  );
};
