import React from "react";
import { CustomButton } from "../customButton/CustomButton";


export const Card = ({title, icon, content, onClick, textButton, hiddeButton}) => {

  return (
    <div className="bg-[#F0F0F0] w-80 h-48 rounded-md shadow-md">
      <div className="flex flex-row">
            <div className="w-1/2">
                <p className="p-4 text-black font-bold">{title}</p>
            </div>
            <div className="w-1/2 flex justify-end">
                <div className="m-[0.82rem] p-1 bg-[#004870] w-10 h-10 flex items-center justify-center rounded-lg">
                    <span className="material-symbols-outlined text-white">
                        {icon}
                    </span>
                </div>
            </div>
      </div>
      
        <h1 className="text-5xl pl-[1rem]">{content}</h1>
      
      <div className="pt-3 flex justify-center items-center">
        <CustomButton invisible={hiddeButton} color="#FFC600" text={textButton} onClick={onClick} colorText="black"/>
      </div>
    </div>
  );
};
