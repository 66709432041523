import React from "react";
import { ProgresBar } from "../progressBar/ProgresBar";
import { TableStrippedRow } from "../TableStrippedRow/TableStrippedRow";
import { CustomButton } from "../customButton/CustomButton";

export const ModalCustom = ({store, user, onClose, title, subtitle, task }) => {

    const TABLE_HEAD = ["Tarea" ,"Descripcion", "Status", "Evidencia"];
    const TABLE_ROWS = [
        {
          tarea: "Revisar salidas",
          descripcion: "Revisar en la odoo las salidas",
          status: "En progreso",
        },
        {
          tarea: "Revisar entradas",
          descripcion: "Revisar las entradas en odoo",
          status: "Finalizada",
        },
        {
            tarea: "Revisar limpieza",
            descripcion: "Test",
            status: "Finalizada",
        },
        {
            tarea: "Revisar inventario",
            descripcion: "Test",
            status: "En progreso",
        },
        {
            tarea: "Test 1",
            descripcion: "Test",
            status: "test",
        },
        {
            tarea: "Test 2",
            descripcion: "Test",
            status: "test",
        },
        
      ];
    
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto mx-auto w-[70%]">
      <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
      <div className="bg-white rounded-lg p-8 w-full z-50 overflow-y-auto">
        <div className="flex justify-end">
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                />
                </svg>
            </button>
            </div>
        <div className="flex justify-center items-center">
          <h2 className="text-2xl font-semibold text-[#004876]">{title}</h2>
        </div>
        {/* Usuario */}
        <div className="flex flex-col">
            {user && (<div className="flex flex-row">
                <div className="flex flex-row w-1/2">
                    <div className="w-[30%] rounded-full overflow-hidden bg-gray-200 flex items-center justify-center border border-gray-400">
                        <img src="https://phantom-marca-mx.unidadeditorial.es/5d9f6dd48af532196162d4d8946f4a2d/resize/828/f/jpg/mx/assets/multimedia/imagenes/2023/04/09/16810590961899.jpg" alt="Imagen de perfil" className="w-full h-full object-cover" />
                    </div>
    
                    <div className="mt-4 pl-4">
                    <p>
                        <span className="font-semibold">ID:</span> {user.id}
                    </p>
                    <p>
                        <span className="font-semibold">Nombre:</span> {user.name}
                    </p>
                    <p>
                        <span className="font-semibold">Cargo:</span> {user.job}
                    </p>
                    <p>
                        <span className="font-semibold">Fecha:</span> {user.date}
                    </p>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="flex justify-end">
                        <ProgresBar title={"Progreso semanal"} progress={30} />
                    </div>
                </div>
            </div>)}
            {/* Usuario */}
            {/* Task */}
            {task && (<div className="flex flex-row">
                <div className="flex flex-col justify-center w-1/2">
                    <p className="font-bold">{task.task}</p>
                    <p className="font-bold">Descripcion de tarea: <p className="font-normal">{task.description}</p></p>
                    {/* <p>Selecciona un usuario para ver sus evidencias</p> */}
                </div>
                <div className="w-1/2">
                    <div className="flex justify-end">
                        <ProgresBar title={"Progreso semanal"} progress={30} />
                    </div>
                </div>
            </div>)}
            {/* Task */}
            {/* store */}
            {store && (<div className="flex flex-row">
                <div className="flex flex-row w-1/2">
    
                    <div className="mt-4 pl-4">
                    <p>
                        <span className="font-semibold">Nombre:</span> {store.name}
                    </p>
                    <p>
                        <span className="font-semibold">Ubicacion:</span> {store.location}
                    </p>
                    <p>
                        <span className="font-semibold">Salud de la tienda:</span> {store.healt}
                    </p>
                    <CustomButton text={"Descargar informe"} colorText={"black"} />
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="flex justify-end">
                        <ProgresBar title={"Progreso semanal"} progress={30} />
                    </div>
                </div>
            </div>)}
            {/* store */}
            <h2 className="text-xl font-semibold text-[#004876] pt-5">{subtitle}</h2>
            <TableStrippedRow TABLE_HEAD={TABLE_HEAD} TABLE_ROWS={TABLE_ROWS} />
        </div>
      </div>
    </div>
  );
};
