import React, { createContext, useContext, useState, useEffect } from 'react';
import { LoginAPI } from '../api/login';
import Cookies from 'universal-cookie';

const cookies = new Cookies();


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const loginApi = new LoginAPI();
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');

    if (storedIsLoggedIn === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  
  
  const login = async (credential) => {
    const gmailData = parseJwt(credential.credential);
    const email = gmailData.email;
    const name = gmailData.name;
    const firstName = gmailData.given_name;
  
    const data = {
      "username": email
    };
  
    const response = await loginApi.loginEP(data);
    if (response.status === 200) {
      const { accessToken } = await response.data;

      // Set the access token as an HTTP-only cookie
      cookies.set('access_token', accessToken, {
        path: '/',
        httpOnly: true,
        secure: true // Set to true if using HTTPS
      });
      console.log(name);
      console.log(email);
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("isLoggedIn", 'true');
      localStorage.setItem("email", email);
      localStorage.setItem("name", response.data.username);
      localStorage.setItem("company", response.data.company);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("token", response.data.token);
      setIsLoggedIn(true);
      return 'ok'
    }else{
      return 'error'
    }
  };
  

  const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  
    return JSON.parse(jsonPayload);
  };

  const logout = () => {
    // Lógica de cierre de sesión aquí
    localStorage.clear();
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
