import React, { useEffect, useState } from 'react'
import { Navbar } from '../../components/navbar/Navbar';
import { TaskApi } from '../../api/stores';
import { TopBar } from '../../components/topBar/TopBar';
import { formatTasksTable } from '../../helpers/formatTable';
import CircularProgress from '@mui/material/CircularProgress';
// import { Card } from '../../components/card/Card';
import { DotMap } from '../../components/dotMap/DotMap';
import { ColumnChart } from '../../components/columChart/ColumnChart';
import { TableData } from '../../components/tableData/TableData';
import { ProgresBar } from '../../components/progressBar/ProgresBar';




export const Dashboard = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [TABLE_HEAD, setTABLE_HEAD] = useState([]);
    const [TABLE_ROWS, setTABLE_ROWS] = useState([]);


    useEffect(() => {
        const tasksApi = new TaskApi();
        const today = new Date();
        const lastMonday = new Date(today);
        lastMonday.setDate(lastMonday.getDate() - (today.getDay() + 6) % 7 - 7); // Comienzo de la semana pasada (lunes)
        const lastSunday = new Date(lastMonday);
        lastSunday.setDate(lastSunday.getDate() + 6); 
        const initTasks = async () => {
            try {
                const response = await tasksApi.getTasks();
                if (response.status === 200) {
                    console.log(response.data);
                    const tableData = formatTasksTable(response.data);
                    setTABLE_HEAD(tableData.headers);
                    setTABLE_ROWS(tableData.formatData);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
  
        initTasks();
    }, []);    

    const jsonData = {
        "data": [
          { "name": "Reloj de Pachuca", "latitud": 20.1275, "longitud": -98.731944, "color": "#C60000" },
          { "name": "UPP", "latitud": 20.1215, "longitud": -97.731944, "color": "#C60000" },
          { "name": "Rabbit", "latitud": 20.1245, "longitud": -98.731951, "color": "#C60000" },
          { "name": "Moliere", "latitud": 19.431587, "longitud": -99.2018667, "color": "#C60000" },
          { "name": "Chichén Itzá", "latitud": 20.6830, "longitud": -88.5686, "color": "#FFC600" },
          { "name": "Palacio de Bellas Artes", "latitud": 19.4353, "longitud": -99.1413, "color": "#FFC600" },
          { "name": "Zócalo de la Ciudad de México", "latitud": 19.4326, "longitud": -99.1332, "color": "#FFC600" },
          { "name": "Teotihuacán", "latitud": 19.6926, "longitud": -98.8425, "color": "#FFC600" },
          { "name": "Catedral Metropolitana de la Ciudad de México", "latitud": 19.4362, "longitud": -99.1332, "color": "#52D25C" },
          { "name": "Puerto Vallarta", "latitud": 20.6534, "longitud": -105.2253,  "color": "#52D25C" },
          { "name": "Playa del Carmen", "latitud": 20.6296, "longitud": -87.0739, "color": "#52D25C" },
          { "name": "Huejutla", "latitud": 21.1389999, "longitud": -98.4231678, "color": "#52D25C" }
          ]};

    const TaskByHFM = {
        "typeData": "Porcentaje de rendimiento",
        "data": [
            {"Name": "Edwin Montes", "Value": 55},
            {"Name": "Jonathan Gutierrez", "Value": 85},
            {"Name": "Emmanuel Morales", "Value": 89},
            {"Name": "Cesar Martinez", "Value": 15},
            {"Name": "Alex Quezada", "Value": 75},
            {"Name": "Javier Ibarra", "Value": 80},
            {"Name": "Diego Valdez", "Value": 60},
            {"Name": "Fernando Vargas", "Value": 55},
            {"Name": "Antonio Salas", "Value": 86},
            {"Name": "Alan Canales", "Value": 85},
            {"Name": "Diego Lopez", "Value": 80},
            {"Name": "Arlette Armenta", "Value": 70},
            {"Name": "Emmanuel Rosas", "Value": 35},
            {"Name": "Vivian Mendoza", "Value": 98},
            {"Name": "Jael Diaz", "Value": 22},
            {"Name": "Jesus Martinez", "Value": 68},
            {"Name": "Hosmany Garnica", "Value": 10}
        ]
    };
    const nombres = TaskByHFM.data.map(item => item.Name);


    return (
        <div>
            {isLoading && (
                <div className="absolute inset-0 flex justify-center items-center bg-opacity-50 bg-gray-800 z-50">
                    <CircularProgress style={{ color: '#FFC600' }} />
                </div>
            )}
            <Navbar />
            <TopBar />
            <div className={isLoading ? "filter blur-lg flex pl-[4rem] flex max-[430px]:pl-0 max-[430px]:flex-col" : "flex pl-[4rem] flex max-[430px]:pl-0 max-[430px]:flex-col"}>
                <div className="w-1/2 flex flex-col justify-center items-center max-[430px]:w-[100%]">
                    {/* Parte izquierda */}
                    {/* <Card icon="checklist" title="Tareas Activas" content="555" onClick={handleTaskCard} textButton="Crear tarea"/> */}
                    <ProgresBar title={"Progreso semanal"} progress={30} />
                    <div className='pt-10 w-[100%] flex justify-center items-center'>
                        <DotMap jsonData={jsonData} />
                    </div> 
                    {/* <div className='pt-10 flex flex-col w-[100%] justify-center items-center lg:hidden'>
                        <ColumnChart />
                        <div className='pt-10 w-[100%] flex justify-center items-center'>
                            <TableData />
                        </div>
                    </div>                    */}
                </div>
                <div className="w-1/2 flex flex-col justify-start items-center max-[430px]:w-[100%] max-[430px]:pt-10">
                    {/* Parte derecha */}
                   <ColumnChart categories={nombres} typeData={TaskByHFM.typeData} values={TaskByHFM.data.map(item => item.Value)}/>
                   <div className='pt-10 w-[100%] flex justify-center items-center'>
                        <TableData TABLE_HEAD={TABLE_HEAD} TABLE_ROWS={TABLE_ROWS} />
                    </div>  
                </div>
            </div>
        </div>
    );
};
