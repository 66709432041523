import axios from "./axios"

export const forceReload = () => {
  localStorage.removeItem(
    "token"
  ); /* Remove the token so that the "front" can log out when the token changes, you can't call the log out function because it asks for the token (which doesn't exist anymore). */
  window.location.reload();
};

export class HttpRequest {
  async post(url, json, config) {
    try {
      return await axios.post(url, json, config);
    } catch (error) {
      error.response.status === 401 && forceReload();
      return error;
    }
  }

  async put(url, json, config) {
    try {
      return await axios.put(url, json, config);
    } catch (error) {
      error.response.status === 401 && forceReload();
      return error;
    }
  }

  async get(url, config) {
    try {
      return await axios.get(url, config);
    } catch (error) {
      error.response.status === 401 && forceReload();
      return error;
    }
  }

  async delete(url, data) {
    try {
      return await axios.delete(url, data);
    } catch (error) {
      error.response.status === 401 && forceReload();
      return error;
    }
  }
}
